<template>
  <v-row
    align-content="center"
    justify="center"
    :style="{ maxHeight: 'calc(100vh - 88px)' }"
  >
    <v-col cols="12" sm="8" md="6">
      <v-card outlined>
        <v-card-title class="sticky-header">
          Projects
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on" @click="dialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Project</span>
          </v-tooltip>
        </v-card-title>
        <v-list class="scrollable-list" v-if="projects.length">
          <v-divider />
          <v-list-item
            v-for="proj in projects"
            :key="proj.uuid"
            @click="Math.random"
            :class="currentProject && proj.uuid === currentProject.uuid ? 'primary--text' : ''"
          >
            <v-list-item-content @click="setCurrentProject(proj)">
              <v-list-item-title>{{ proj.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ proj.desc }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="currentProject && currentProject.uuid === proj.uuid">
              <v-chip small color="primary" dark>Current</v-chip>
            </v-list-item-action>
            <v-list-item-action
              v-if="projects.length > 1 && (!currentProject || currentProject.uuid !== proj.uuid)"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on="on"><v-icon>mdi-close</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item @click="removeProject(proj)">
                    <v-list-item-title class="error--text">
                      <v-icon left color="error">mdi-trash-can-outline</v-icon>
                      Delete Project and Files
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn text icon v-on="on" @click="editProject(proj)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Project</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-actions v-else>
          <v-spacer />
          <v-btn color="primary" depressed @click="dialog = true">
            <v-icon left>mdi-plus</v-icon>
            Create A New Project
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <save-project v-model="dialog" :id="id" @input="!$event && reset()" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SaveProject from './save-project.vue';
import feathersClient from '../../store/feathers-client'; // Import Feathers client directly

export default {
  components: {
    SaveProject,
  },
  data() {
    return {
      dialog: false,
      id: null,
    };
  },
  computed: {
    ...mapGetters('projects', { projectFind: 'find' }),
    currentProject() {
      const { Project } = this.$FeathersVuex.api;
      return Project.findInStore({ query: { uuid: this.$store.getters.currentProject } }).data[0] || {};
    },
    projects() {
      return this.projectFind({ query: { $limit: 9999 } }).data;
    },
  },
  methods: {
    setCurrentProject(item) {
      this.$store.commit('setCurrentProject', item.uuid);
    },
    async removeProject(project) {
      try {
        const { File } = this.$FeathersVuex.api;
        const projectService = feathersClient.service('projects');
        // Delete all files related to the project (if available)
        const files = await File.find({ query: { projectId: project.uuid } }).catch(() => []);
        await Promise.all(files.map((file) => file.remove()));
        // Always try to delete from Feathers (even if local storage is cleared)
        await projectService.remove(project._id).catch(error => {
          if (error.code === 404) {
            console.warn(`Project ${project._id} not found on the local Storage. Removing from Vuex manually.`);
          } else {
            throw error; // Re-throw errors that are not 404
          }
        });
        // Ensure the project is removed from Vuex state immediately
        this.$store.commit('projects/removeItem', project._id);
        // Force Vue to update UI
        this.$forceUpdate();
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    },
    editProject(pro) {
      this.id = pro._id;
      this.dialog = true;
    },
    reset() {
      this.id = null;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.scrollable-list {
  max-height: 430px;
  overflow-y: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  box-sizing: border-box;
  /* scrollbar-width: none;
  -ms-autohiding-scrollbar: none; */
}

/* .scrollable-list::-webkit-scrollbar {
  display: none;
} */
</style>
